<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
    </div>
    <div class="tab-pane fade show active text-center" v-else-if="innings.length == 0">
        <p>{{ $t("No overs data found") }}.</p>
    </div>
    <div v-else>
        <div class="innings_tabs cr-submain-tab">
            <ul>
                <li :key="inning.order" v-for="(inning,index) in innings">
                    <a :class="activeInningIndex == index ? 'selected':''"
                       @click=" activeInningIndex = index; getCommentary(inning, true)"
                       style="cursor: pointer" href="javascript:;">
                        <span>{{ inning.caption }}
                        </span>
                        <i v-show="loading && activeInningIndex == index" class="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <div class="over_tab" v-if="matchOvers">
            <div v-for="over in Object.keys(matchOvers).sort((a, b) => b - a)" :key="over.id" class="over_tab_per_ball">
                <span  class="over-over">
                    <span> {{ $t("cric.Overs") }} {{over}}</span>
                </span>
                <ul class="per-ball-list">
                    <li v-for="ball in Object.keys(matchOvers[over].balls).sort((a, b) => b - a)" :key="ball.id">
                        <span v-if="matchOvers[over].balls[ball].d_score != '0'" :class="matchOvers[over].balls[ball].d_score == '4' ? 'four-ball': (matchOvers[over].balls[ball].d_score == '6' ? 'six-ball' : (matchOvers[over].balls[ball].d_score == 'W' ? 'wiket-ball' : (matchOvers[over].balls[ball].d_score.indexOf('wd') > -1)?'wide-ball': (matchOvers[over].balls[ball].d_score.indexOf('nb') > -1)?'no-ball': ''))">
                            {{matchOvers[over].balls[ball].d_score}}
                        </span>
                        <span v-if="matchOvers[over].balls[ball].d_score == '0'" >•</span>
                    </li>
                </ul>
            </div>
        </div>
        <span v-if="hasOvers" @click="getCommentary(innings[activeInningIndex])" class="cr-load-more" >Load More <i v-show="loading" class="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i></span>
    </div>
</template>

<script>
    export default {
        name: "over",
        data() {
            return {
                matchOvers: null,
                activeInningIndex: 0,    //active tab inning
                matchCurrentInning: 0,
                innings: '',
                loading: false,
                hasOvers: false,
                offset: 0,
                inningsInterval: null

            }
        },
        mounted() {
            this.main();
            var _this = this;
            this.$root.$on('CricMatchUpdated', data => {
                if (data.match_id == _this.$route.params.id && _this.activeInningIndex == _this.matchCurrentInning) {
                    _this.getCommentary(_this.innings[_this.innings.length - 1], true)
                }
            });
            this.inningsInterval = setInterval(() => {
                this.innings = this.$store.getters.getMatchDetailResponse.innings;
            }, 60000);
        },
        beforeDestroy() {
            this.$root.$off('CricMatchUpdated')
            clearInterval(this.inningsInterval);
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_over_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_over_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_over_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        serverPrefetch() {
            return this.main();
        },
        methods: {
            getCommentary(inning, tabClick = false) {
                if (this.matchOvers == null) {
                    this.is_data_ready = false;
                }
                if (tabClick) {
                    // this.is_data_ready = false;
                    this.offset = 0;
                    this.hasOvers = false;
                    this.matchOvers = null;
                }
                this.loading = true;
                const offset = this.offset;
                return this.$axios
                    .get(this.$MATCH_OVER_API.replace(':match', this.$route.params.slug), {
                        params: {
                            inning: inning.order,
                            offset, offset
                        }
                    })
                    .then(response => {
                        if (response.data) {
                            if (this.matchOvers == null) {
                                this.matchOvers = response.data.result.overs;
                            } else {
                                // this.matchOvers = {...this.matchOvers,...response.data.result.overs};
                                this.matchOvers = Object.assign(this.matchOvers, response.data.result.overs);
                                // Array.prototype.push.apply(this.matchOvers, response.data.result.overs);
                            }
                            if (Object.keys(response.data.result.overs).length < 8 || this.offset == response.data.result.offset || (response.data.result.offset - this.offset) < 45) {
                                this.hasOvers = false;
                            } else if (Object.keys(response.data.result.overs).length > 0) {
                                this.hasOvers = true;
                            }
                            this.offset = response.data.result.offset;
                        }
                        this.is_data_ready = true;
                        this.loading = false;
                    })
            },
            main() {
                this.innings = this.$store.getters.getMatchDetailResponse.innings;

                if (this.innings[0] && this.innings[0].order) {
                    this.matchCurrentInning = this.activeInningIndex = this.innings.length - 1
                    return this.getCommentary(this.innings[this.innings.length - 1])
                }else {
                    this.is_data_ready = true;
                }
            }
        },

    }
</script>

<style scoped>

</style>
